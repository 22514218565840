@import url("https://cdnjs.cloudflare.com/ajax/libs/antd/4.21.5/antd.min.css");

@font-face {
    /* TODO: Font Family https://www.dfonts.org/assets/visby-round-font-family/ */
    font-family: 'VisbyRoundCF-Regular';
    src: local('VisbyRoundCF-Regular'), url(./assets/VisbyRoundCF-Regular.woff) format('woff');
    font-weight: normal;
}

@font-face {
    /* TODO: Font Family https://www.dfonts.org/assets/visby-round-font-family/ */
    font-family: 'VisbyRoundCF-DemiBold';
    src: local('VisbyRoundCF-DemiBold'), url(./assets/VisbyRoundCF-DemiBold.woff) format('woff');
    font-weight: normal;
}

@font-face {
    /* TODO: Font Family https://www.dfonts.org/assets/visby-round-font-family/ */
    font-family: 'VisbyRoundCF-Heavy';
    src: local('VisbyRoundCF-Heavy'), url(./assets/VisbyRoundCF-Heavy.woff) format('woff');
    font-weight: normal;
}

body {
    font-family: 'VisbyRoundCF-Regular';
    margin: 0px;
}

.form-title { 
    font-size: 42px; 
    font-family: 'VisbyRoundCF-Heavy'; 
    letter-spacing: 0.5px; 
    color: white; 
    padding-bottom: 12px; 
}

.form-subtitle {
    font-size: 18px;
    font-family: 'VisbyRoundCF-Regular';
    letter-spacing: 0.5px;
    color: white;
    padding-bottom: 24px;
}

.background-image {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-gradient-dark {
    width: 100vw;
    height: 100%;
    overflow-y: scroll;
    background: linear-gradient(66deg, #2bc1ee 0%, #2bc1ee 50%, #75c8e4 100%);
    /* ^ Built with https://cssgradient.io/ */
}

.background-gradient-light {
    width: 100vw;
    height: 100%;
    overflow-y: scroll;
    background: linear-gradient(66deg, #75c8e4 0%, #75c8e4 50%, #2bc1ee 100%);
    /* ^ Built with https://cssgradient.io/ */
}
